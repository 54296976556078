<template>
  <v-footer
    variant="fixed"
    :padless="true"
  >
    <v-card
      flat
      width="100%"
      class="red lighten-1 text-center"
    >
    <p>Built with <a target="_blank" rel="noopener noreferrer" href="https://vuejs.org/">Vue3</a> & <a target="_blank" rel="noopener noreferrer" href="https://next.vuetifyjs.com/en">Vuetify</a> - Hosted via <a target="_blank" rel="noopener noreferrer" href="https://pages.cloudflare.com/">Cloudflare pages</a></p>
    </v-card>
  </v-footer>
</template>