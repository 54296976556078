<template>
  <main-layout>
    <h1>Arch Linux mirror</h1>
    <p>Replace your pacman mirrorlist file: /etc/pacman.d/mirrorlist</p>
    <pre>
      <code>
# https://bikesuper.xyz/archmirror
# version 1.0.1

Server = https://archmirror.bikesuper.xyz/$repo/os/$arch
Server = http://archmirror-nl1.bikesuper.xyz/$repo/os/$arch
Server = https://cloudflaremirrors.com/archlinux/$repo/os/$arch
      </code>
    </pre>

    <p>Use curl to overwrite current mirror list</p>
    <pre><code>curl -v -o /etc/pacman.d/mirrorlist https://bikesuper.xyz/mirrorlist</code></pre>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout'
export default {
  components: {
    MainLayout
  }
}
</script>