<template>
  <main-layout>
    <v-container class="grey lighten-5">
      <v-row no-gutters class="page--header">
        <v-col cols="3">
          <h1>Start Screen</h1>
          <h3>Links to the bikesuper network!</h3>
        </v-col>
      </v-row>
      <hr />
      <v-row>
        <v-col v-bind:key="name" v-for="card, name in cards_lightning" cols="2">
          <ss-link :name="name" :card="card"/>
        </v-col>
      </v-row>
      <hr />
      <v-row>
        <v-col v-bind:key="name" v-for="card, name in cards_infra" cols="2">
          <ss-link :name="name" :card="card"/>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout'
import SSLink from '../components/SSLink'
export default {
  components: {
    MainLayout,
    'ss-link': SSLink
  },
  data() {
    
    return {
      cards_lightning: {
        mynode: {
          title: 'MyNode',
          link: 'https://mynode.bikesuper.xyz/',
        },
        rtl: {
          title: 'Ride The Lightning',
          link: 'https://rtl.bikesuper.xyz/',
        },
        mempool: {
          title: 'Mempool',
          link: 'https://mempool.bikesuper.xyz/',
        },
      },
      cards_infra: {
        grafana: {
          title: 'Grafana',
          link: 'https://grafana.bikesuper.xyz/',
        },
        gitlab: {
          title: 'Gitlab',
          link: 'https://gitlab.bikesuper.xyz/',
        },
        opnsense: {
          title: 'Opnsense',
          link: 'https://opnsense.bikesuper.xyz/',
        },
        vm_opnsense: {
          title: 'VM Opnsense',
          link: 'https://vm-opnsense.bikesuper.xyz/',
          logo: 'opnsense'
        },
        truenas: {
          title: 'TrueNAS',
          link: 'https://truenas.bikesuper.xyz/',
        },
      }
    };
  },
}
</script>