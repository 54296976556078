<template>
  <main-layout>
    <h1>bikesuper.xyz</h1>
    <p>More content coming soon!</p>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout'
export default {
  components: {
    MainLayout
  }
}
</script>