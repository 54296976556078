<template>
  <v-app theme="dark">
    <!-- <ul>
      <li><v-link href="/">Home</v-link></li>
      <li><v-link href="/lightning">Lightning</v-link></li>
    </ul> -->
    <slot></slot>
    <footer-layout></footer-layout>
  </v-app>
</template>

<script>
import FooterLayout from '../layouts/FooterLayout'
export default {
  components: {
    FooterLayout
  }
}
</script>