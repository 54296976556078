<template>
  <v-card>
    <a :href="card.link"> 
      <v-img
        :src="require(`@/assets/start-screen/${imageName}.png`)"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        cover
      />
    </a>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn size="small" variant="outlined" :href="card.link" block>{{card.title}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    card: {
      type: Object,
      required: true
    },
  },
  computed: {
    imageName() {
      if (this.card.logo) {
        return this.card.logo;
      }
  
      return `${ this.name }`
    }
  },
}
</script>