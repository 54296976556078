import { createApp, h } from 'vue'
import routes from './routes'

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi-svg'
import { createVuetify } from 'vuetify'
//import App from './App.vue'

const SimpleRouterApp = {
    data: () => ({
        currentRoute: window.location.pathname
    }),

    computed: {
        ViewComponent() {
            const matchingPage = routes[this.currentRoute] || '404'
            return require(`./pages/${matchingPage}.vue`).default
        }
    },

    render() {
        return h(this.ViewComponent)
    },

    created() {
        window.addEventListener('popstate', () => {
            this.currentRoute = window.location.pathname
        })
    }
}

const app = createApp(SimpleRouterApp)
const vuetify = createVuetify({
        icons: {
            defaultSet: 'mdi',
            aliases,
                sets: {
                    mdi,
            }
        },
    })

app.use(vuetify)
app.mount('#app')