<template>
  <main-layout>
    <v-container class="grey lighten-5">
      <v-row no-gutters class="page--header">
        <v-col cols="3">
          <h1>Lightning Node <v-icon>{{icons.mdiLightningBolt}}</v-icon></h1>
          <h3>Living on the lightning network!</h3>
        </v-col>

        <v-col cols="7">
          <div class="header--buttons">
            <a target="_blank" class="btn--1MilNode" rel="noopener noreferrer" :href="link1MilNode">
              <v-btn
                flat
                color="primary">
                1ml.com
              </v-btn>
            </a>
            <a target="_blank" class="btn--LNPlus" rel="noopener noreferrer" :href="linkLNPlus">
              <v-btn
                flat
                color="primary">
                LightningNetwork+
              </v-btn>
            </a>
            <a target="_blank" class="btn--LNRouter" rel="noopener noreferrer" :href="linkLNRouter">
              <v-btn
                flat
                color="primary">
                lnrouter.app
              </v-btn>
            </a>
            <a target="_blank" class="btn--AmbossSpace" rel="noopener noreferrer" :href="linkAmbossSpace">
              <v-btn
                flat
                color="primary">
                Amboss.space
              </v-btn>
            </a>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <h2>Connect</h2>
          <p>Connect to this lightning node via Tor. Open a channel to start routing some payments!</p>
          <v-form>
            <v-row>
                <v-text-field
                  v-model="pubkey"
                  label="Public Key"
                  hide-details=true
                  readonly
                ></v-text-field>
                <!-- <v-btn dark x-large color="orange"></v-btn> -->
            </v-row>
            <v-row>
              <v-text-field
                v-model="tor3"
                label="Tor Address"
                hide-details=true
                readonly
              ></v-text-field>
              <!-- <v-btn dark x-large color="orange"></v-btn> -->
            </v-row>
          </v-form>
        </v-col>

        <v-col offset-md="1" cols="3">
          <vue-qrcode :value=address :options="{ width: 240 }"></vue-qrcode>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" md="4" sm="" xs="12">
          <h2>Uptime</h2>
          <p>Node is kept online as much as possible but updates are applied when available. Monitored via <a href="https://lightning.watch/" target="_blank" rel="noopener noreferrer">Lightning.Watch</a> Telegram notifications.</p>
        </v-col>
        <v-col cols="2" md="3" sm="" xs="12">
          <h2>Software</h2>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/mynodebtc/mynode">MyNode</a> running via Virtual Machine</li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/mempool/mempool">mempool</a> - <a target="_blank" rel="noopener noreferrer" href="https://mempool.bikesuper.xyz">mempool.bikesuper.xyz</a></li>
          </ul>
          <h2>Hardware</h2>
          <ul>
            <li><p>4x 3.2GHz AMD cores</p></li>
            <li><p>8GB DDR4 RAM</p></li>
            <li><p>1TB NVME RAID1 storage</p></li>
          </ul>
        </v-col>
        <v-col cols="4" md="4" sm="" xs="12">
          <h2>Stats</h2>
          <v-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>LN Sats Capacity</td>
                  <td>{{ statsCapacity }}</td>
                </tr>
                <tr>
                  <td>LN Channels</td>
                  <td>{{ statsChannelCount }}</td>
                </tr>
                <tr>
                  <td>BTC Current Block</td>
                  <td>{{ statsLatestBlock }}</td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="0">
          <h2>Mainnet Mempool</h2>
          <iframe class="mempoolTv--iframe" scrolling="no" src='https://mempool.bikesuper.xyz/tv'></iframe>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>
</template>

<style>
.header--buttons {
  margin-left: 10px;
}

.header--buttons .v-btn {
  margin-right: 10px;
}

.btn--1MilNode > button {
  background: rgb(34, 111, 155);
}

.btn--LNRouter > button {
  background: rgb(39, 163, 164);
}

.btn--LNPlus > button {
  background: rgb(109, 40, 217) linear-gradient(rgb(93, 34, 184), rgb(160, 91, 255)) repeat scroll 0% 0%;
}

.btn--AmbossSpace > button {
  background-image:linear-gradient(240deg, rgb(121, 40, 202) 0%, rgb(255, 0, 128) 100%);
}

/* Hide input details elemets */
.v-input__details {
  display: none !important;
}

.page--header {
  margin-bottom: 50px !important;
}

.mempoolTv--iframe {
  width: 100%;
  min-height: 950px;
  border: 0;
  pointer-events: none;
}
</style>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import MainLayout from '../layouts/MainLayout'
import { mdiLightningBolt } from '@mdi/js';

export default {
  components: {
    MainLayout,
    VueQrcode,
  },
  data() {
    let pubkey = '026521f9d7a6816932f26e322b09c43b80aff722d527fc0937f1e4b9810ee601e3'
    let tor3 = '5udhxxcwaljaad7mnfjp4b6ngkqabotj2sitiitx5o2okmgrwaw3nfyd.onion:9735'
    return {
      icons: {
        mdiLightningBolt
      },
      pubkey: pubkey,
      tor3: tor3,
      address: `${pubkey}@${tor3}`,
      statsCapacity: '-',
      statsChannelCount: '-',
      statsLatestBlock: '-',
      stats1ML: null
    };
  },
  computed: {
    link1MilNode() {
      return `https://1ml.com/node/${ this.pubkey }`
    },
    linkLNPlus() {
     return `https://lightningnetwork.plus/nodes/${ this.pubkey }`
    },
    linkAmbossSpace() {
     return `https://amboss.space/node/${ this.pubkey }`
    },
    linkLNRouter() {
      return `https://lnrouter.app/scores/terminal?id=${ this.pubkey }`
    }
  },
  mounted: function () {
    this.startWSListen();
    this.getCurrentBlock();
    this.get1MLStats();
  },
  methods: {
    get1MLStats() {
      const _this = this;
      fetch(`https://bikesuper.xyz/stats/lightning`, {
          method: 'GET',
          referrerPolicy: 'no-referrer'
      }).then(function(response) {
          console.log(response);
          return response.json()
      }).then(function(json) {
          console.log(json);``
          _this.stats1ML = json;
          _this.statsCapacity = json.capacity;
          _this.statsChannelCount = json.channelcount;
      }).catch(function(ex) {
          console.log('parsing failed', ex)
      })
    },
    getCurrentBlock() {
      const _this = this;
      fetch('https://mempool.bikesuper.xyz/api/blocks/tip/height', {
          method: 'GET',
          credentials: 'same-origin',
          referrerPolicy: 'no-referrer'
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          _this.statsLatestBlock = json;
      }).catch(function(ex) {
          console.log('parsing failed', ex)
      })
    },
    startWSListen() {
      const that = this;
      window.addEventListener("btc_block_height", (e) => {
        that.statsLatestBlock = e.detail;
      }, false);
    }
  }
}
</script>